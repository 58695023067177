<template>
  <div class="age-muac-result-table">
    <div class="age-muac-result-table-title-container">
      <div class="txt-14 txt-font-din-medium">{{ title }}</div>
    </div>
    <a-table :columns="columns" :data-source="tableData" :pagination="false" :show-header="false" size="small"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AnthropometryAgeMuacResultsItem',
  props: {
    title: {
      type: String,
      required: true
    },
    msd: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState({
      isExhaustive: state => state.survey?.metadata?.samplingMethod === 'Exhaustive'
    }),
    columns() {
      if (!this.msd) {
        return [];
      }
      return [
        {
          title: 'Level',
          dataIndex: 'level',
          key: 'level'
        },
        {
          title: 'N',
          dataIndex: 'n',
          key: 'n'
        },
        {
          title: 'X',
          dataIndex: 'x',
          key: 'x'
        },
        {
          title: 'prop',
          dataIndex: 'prop',
          key: 'prop'
        },
        {
          title: 'details',
          dataIndex: 'propDetails',
          key: 'propDetails'
        }
      ];
    },
    tableData() {
      if (!this.msd) {
        return [];
      }
      return this.msd.filter((data) => data.level != null).map((data, index) => ({
        key: index + 1,
        level: data.level,
        n: `(${data.n || '0'}): `,
        x: `(${data.x || '0'})`,
        prop: data.prop != null ? `${(data.prop * 100).toFixed(1)}%` : '-',
        propDetails: this.isExhaustive ? '' : `(${data.prop_low != null ? (data.prop_low * 100).toFixed(1) : 'NA'}-${data.prop_upp != null ? (data.prop_upp * 100).toFixed(1) : 'NA'} ${this.$t('values.ci', { number: '95%' })})`
      }));
    }
  }
};
</script>

<style lang="scss">
.age-muac-result-table-title-container {
  margin-bottom: 10px;
}
.age-muac-result-table {
  margin-bottom: 20px;

  .ant-table-body {
    margin: 0 !important;
  }

  .ant-table-small {
    border: none !important;
  }

  .ant-table-tbody > tr > td {
    padding: 0 !important;
    border: none !important;
  }
}
</style>
